<template>
  <section class="section-carousel" v-if="$locale">
    <div class="section-carousel-container" v-if="data && data.length">
      <div class="section-carousel-wrap">
        <div class="section-carousel-title main-title">
          <p class="main-title-top">{{ $locale.words["stories"] }}</p>
        </div>
        <Spacer num="1" />
        <div class="section-carousel-filter">
          <div class="section-carousel-filter-content main-tv">
            <div class="section-carousel-filter-category" v-for="creator in data" :key="creator._id">
              <StoryCard :creator="creator" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {
    StoriesBar: () => import("../landing/StoriesBar.vue"),
    StoryCard: () => import("./StoryCard.vue"),
  },
  data: function() {
    return {
      data: null,
      settings: {
        arrows: true,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        adaptiveHeight: true,
        cancelable: false,
        centerMode: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              arrows: true,
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              arrows: false,
              centerMode: false,
              slidesToShow: 1,
              variableWidth: true,
            },
          },
        ],
      },
    };
  },
  methods: {
    getStories: async function() {
      this.isLoading(true);
      let query = `limit=6`;
      const search = this.$api.get(`${process.env.VUE_APP_API_HOST}posts/stories?${query}`);
      search.then(({ data: { creators } }) => {
        this.data = creators;
        this.isLoading(false);
      });
      search.catch((error) => {
        this.ialert.go({ message: error, unique: true, title: "Error" });
        this.isLoading(false);
      });
    },
  },
  mounted: async function() {
    await this.getStories();
  },
};
</script>

<style lang="scss">
.section-carousel {
  background-color: $white_smoke;
  &-container {
    padding: 0 0 $mpadding * 2 0;
    text-align: center;
  }
  &-header {
    height: 240px;
    margin-bottom: 40px;
  }
  &-title {
    text-transform: uppercase;
  }
  &-filter {
    height: auto;
    padding: 0 $mpadding;
    &-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: $mpadding/2;
    }
    .card-link {
      display: block;
      cursor: pointer;
    }
    &-card {
      position: relative;
      height: auto;
      box-shadow: 0 0 10px $alto;
      padding: 5px;
      width: 100%;
      max-width: 187px;
      display: inline-block;
      overflow: hidden;
      background-color: $white;
      color: $primary_color;
    }
    &-img-wrap {
      display: grid;
      max-width: 100%;
      min-height: 315px;
      background-color: $body_color;
      overflow: hidden;
      background-image: var(--picture);
      background-size: cover;
      background-position: center;
    }
    &-img {
      width: 100%;
      height: 100%;
      pointer-events: none;
      object-fit: cover;
    }
    &-card,
    &-img-wrap {
      border-radius: 12px;
    }
    &-img-tittle {
      font-size: 20px;
      margin-top: 7px;
      text-align: center;
      font-family: $sec_font;
    }
  }
  .slick-slider {
    .slick-list {
      padding: 0 $mpadding/2.5;
    }
  }
  .slick-track {
    display: flex;
  }
  .slick-prev,
  .slick-next {
    z-index: 1;
    height: 100%;
    width: 27px;
    @include Flex(inherit, center, center);
    [class*="iconic"] {
      color: $primary_color;
      width: 100%;
      height: auto;
      svg {
        width: 100%;
        height: auto;
      }
    }
  }
  .slick-prev::before,
  .slick-next::before {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }
  .slick-prev {
    left: -40px;
  }
  .slick-next {
    right: -40px;
  }

  @include min-width($tablet_width) {
    &-filter {
      &-content {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  @include min-width($pc_width) {
    &-filter {
      &-content {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }

  @include min-width($content_width) {
    &-filter {
      &-content {
        grid-template-columns: repeat(6, 1fr);
      }
    }
  }
}
</style>
