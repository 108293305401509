<template>
  <div class="creator-ban">
    <VueSlickCarousel v-bind="settings" ref="carrousel" :key="`carrousel-${index}`">
      <div class="creator-ban-wrap" v-for="(picture, pindex) in getPictures()" :key="`picture-${pindex}`">
        <div class="creator-ban-picture" :style="`--picture:url(${encode_url(picture.src)});`"></div>
      </div>
    </VueSlickCarousel>
    <LandingColumnLabel :creator="user" />
  </div>
</template>

<script>
import LandingColumnLabel from "./LandingColumnLabel.vue";
export default {
  components: { LandingColumnLabel },
  props: ["user", "index"],
  data: function() {
    return {
      numPictures: 3,
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        initialSlide: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 300,
        fade: true,
        cssEase: "linear",
        autoplay: false,
      },
    };
  },
  methods: {
    getLandingData: function() {
      if (!this.user.landing) return this.user.files;

      let files = [];
      let keys = Object.keys(this.user.landing);
      let ignoreKey = ["top", "stories"];

      for (let i = 0; i < keys.length; i++) {
        if (this.user.landing[keys[i]].src && !ignoreKey.includes(keys[i])) {
          files.push(this.user.landing[keys[i]]);
        }
      }

      if (!files.length) {
        files = this.user.files;
      }

      return files;
    },
    getPictures: function() {
      let pictures = [...this.getLandingData()];
      pictures.length = pictures.length > this.numPictures ? this.numPictures : pictures.length;
      return pictures;
    },
    next: function() {
      this.$refs.carrousel.next();
      setTimeout(() => {
        this.$emit(`afterChange${this.index}`, this.index);
      }, this.settings.speed);
    },
  },
};
</script>

<style lang="scss">
.creator-ban {
  pointer-events: none;
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > div,
  &-wrap,
  &-picture {
    width: 100%;
    height: 100%;
  }
  &-picture {
    background-image: var(--picture);
    background-size: cover;
    background-position: center;
    > * {
      pointer-events: none;
    }
  }
}
</style>
