<template>
  <div class="top-creators" v-if="$locale && creators">
    <div class="top-creators-content main-tv">
      <div class="top-creators-title main-title">
        <p class="main-title-top">{{ $locale["landing"]["creators-top-title"] }}</p>
      </div>
      <div class="top-creators-list">
        <router-link class="creator" v-for="(creator, index) in creators" :key="index" :to="`/${creator.user}`">
          <TopCreatorCard :creator="creator" :index="index" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    TopCreatorCard: () => import("./TopCreatorCard.vue"),
  },
  props: ["creators"],
};
</script>

<style lang="scss">
.top-creators {
  background-color: #f7f7f7;
  padding: $mpadding;
  &-title {
    text-align: center;
    margin: $mpadding 0 $mpadding * 2 0;
    text-transform: uppercase;
  }
  &-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $mpadding/2;
    .creator {
      margin: 0 0 $mpadding 0;
      cursor: pointer;
      &-picture {
        display: block;
        width: 100%;
        height: 200px;
        background-image: var(--picture);
        background-size: cover;
        background-position: top 10% center;
        margin: 0 0 $mpadding/2 0;
        border-radius: $mradius;
      }
      &-name {
        font-family: $sec-font;
        font-size: 150%;
        color: $emperor;
      }
      &-label {
        color: $emperor;
      }
    }
  }
  @media (min-width: $tablet-width) {
    padding: $mpadding * 3 $mpadding * 2;
    &-title {
      margin: 0 0 $mpadding 0;
    }
    &-list {
      gap: $mpadding;
      grid-template-columns: repeat(3, 1fr);
      .creator {
        &-picture {
          height: 240px;
        }
      }
    }
  }
  @media (min-width: $pc-width) {
    &-list {
      grid-template-columns: repeat(6, 1fr);
      .creator {
        margin: 0 0 0 0;
        &-picture {
          height: 240px;
        }
      }
    }
  }
}
</style>
