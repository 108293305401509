<template>
  <div class="landing-text" v-if="$locale">
    <div :class="`landing-text-content ${$isProd ? '__prevent' : ''}`">
      <div class="landing-text-animation">
        <div class="top">
          <div class="top-one">
            <LandingTextItem _class="text1" :text="$locale['100_premium']" />
            <LandingTextItem _class="text2" :text="$locale['exclusive_content']" />
          </div>
          <div class="top-two">
            <LandingTextItem _class="text6" :text="$locale['become_a_creator']" />
            <LandingTextItem _class="text7" :text="$locale['turn_your_followers']" />
            <LandingTextItem _class="text8" :text="$locale['into_paid_members']" />
          </div>
        </div>
        <div class="sub">
          <LandingTextItem _class="text3" :text="$locale['become_and_connect']" />
          <LandingTextItem _class="text4" :text="$locale['pick_up_your_domain']" />
          <LandingTextItem _class="text5" :text="$locale['setup_your_subscription']" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let step, setWidths;
import LandingTextItem from "./LandingTextItem.vue";
export default {
  components: { LandingTextItem },
  methods: {
    setClass: function(element, mclass, timeout, state) {
      return new Promise((resolve, reject) => {
        step = setTimeout(() => {
          resolve(state === false ? element?.classList.remove(mclass) : element?.classList.add(mclass));
        }, timeout);
      });
    },
    setWidths: function() {
      if (!setWidths) {
        const allWraps = document.querySelectorAll(`.text .wrap`);
        allWraps.forEach((e) => {
          const width = window.getComputedStyle(e).width;
          e.style.width = width;
        });
        setWidths = true;
      }
    },
    onTxt: function(elem, mclass, timeout, state) {
      const element = typeof elem === "object" ? elem : document.querySelector(`.landing-text ${elem}`);
      return new Promise((resolve, reject) => {
        this.setClass(element, mclass, timeout, state).then(() => {
          resolve();
        });
      });
    },
    await: function(timeout) {
      return new Promise((resolve, reject) => {
        step = setTimeout(() => {
          resolve(timeout);
        }, timeout);
      });
    },
    animIn: async function(element) {
      await this.onTxt(`${element} .wrap`, "outxt", 0, true);
      await this.onTxt(element, "ontxt", 1000, true);
      await this.onTxt(`${element} .wrap`, "outxt", 0, false);
      await this.onTxt(`${element} .wrap`, "ontxt", 500, true);
    },
    animOut: async function(element) {
      await this.onTxt(`${element} .wrap`, "ontxt", 500, false);
      await this.onTxt(`${element} .wrap`, "outxt", 0, true);
      this.onTxt(`${element} .wrap`, "endout", 500, true);
      await this.onTxt(element, "ontxt", 1000, false);
      await this.onTxt(`${element} .wrap`, "endout", 0, false);
      await this.onTxt(`${element} .wrap`, "outxt", 0, false);
    },
    startAnimate: async function() {
      if (!this.$visible) {
        await this.await(500);
        return await this.startAnimate();
      }
      await this.await(500);
      this.setWidths();
      await this.onTxt(".landing-text-content", "ontxt", 0);
      await this.animIn(".text1");
      await this.animIn(".text2");
      await this.animIn(".text3");
      await this.animIn(".text4");
      await this.animIn(".text5");
    },
    reverseAnimate: async function() {
      if (!this.$visible) {
        await this.await(500);
        return await this.reverseAnimate();
      }
      await this.animOut(".text5");
      await this.animOut(".text4");
      await this.animOut(".text3");
      await this.animOut(".text2");
      await this.animOut(".text1");
    },
    startAnimate2: async function() {
      if (!this.$visible) {
        await this.await(500);
        return await this.startAnimate2();
      }
      await this.animIn(".text6");
      await this.animIn(".text7");
      await this.animIn(".text8");
    },
    reverseAnimate2: async function() {
      if (!this.$visible) {
        await this.await(500);
        return await this.reverseAnimate2();
      }
      await this.animOut(".text8");
      await this.animOut(".text7");
      await this.animOut(".text6");
    },
    start: async function() {
      await this.startAnimate();
      await this.await(5000);
      await this.reverseAnimate();
      await this.await(300);
      await this.startAnimate2();
      await this.await(5000);
      await this.reverseAnimate2();
      this.$emit("ended");
    },
  },
  mounted: function() {
    setWidths = false;
    clearTimeout(step);
  },
};
</script>

<style lang="scss">
.landing-text {
  @include Absolute();
  &-content {
    position: relative;
    opacity: 0;
    &.ontxt {
      opacity: 1;
    }
    .top {
      position: relative;
      text-transform: uppercase;
      margin: 0 0 $mpadding * 1.5 0;
      &-two {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
      .text {
        font-size: 24px;
        margin: 0 0 $mpadding/2 0;
        overflow: hidden;
        .wrap {
          display: inline-block;
          background-color: #bd0909c7;
          padding: $mpadding/3 $mpadding;
          font-weight: 600;
          color: #fff;
          overflow: hidden;
        }
        .intxt {
          display: block;
          overflow: hidden;
        }
      }
    }
    .sub {
      margin: $mpadding 0 0 0;
      padding: 0 0 0 0;
      .text {
        font-size: 16px;
        margin: 0 0 $mpadding/2 0;
        overflow: hidden;
        .wrap {
          display: inline-block;
          background-color: #000000c7;
          padding: $mpadding/2 $mpadding * 1.5;
          color: #fff;
          overflow: hidden;
        }
        .intxt {
          display: block;
          overflow: hidden;
        }
      }
    }
    .text {
      .wrap {
        transition: width 3s, max-width 3s, opacity 1s;
        border-radius: $mpadding/1.5;
        .intxt {
          transition: width 1s, max-width 1s;
          .txt {
            display: block;
            width: calc(100vw - 10% - #{$mpadding});
          }
        }
      }
    }
    &.ontxt {
      .text {
        &:not(.ontxt) {
          .wrap {
            transition: width 3s, max-width 3s, opacity 0s;
            max-width: 0;
            opacity: 0;
          }
        }
        &.ontxt {
          .wrap {
            max-width: 100%;
            &.outxt {
              transition: width 1.5s, max-width 1.5s, opacity 3s;
              max-width: 0;
              opacity: 0;
            }
            &.endout {
              opacity: 0;
            }
          }
        }
        .wrap {
          .intxt {
            width: 0;
          }
          &.ontxt {
            opacity: 1;
            max-width: 100%;
            .intxt {
              width: 100%;
            }
          }
        }
      }
    }
  }
  &-animation {
    padding: calc(50% + #{$header-height/1.5}) $mpadding 0 $mpadding;
  }
  @media (min-width: $mobile_m_width) {
    &-content {
      .top {
        .text {
          font-size: 26px;
        }
      }
      .sub {
        .text {
          font-size: 18px;
        }
      }
    }
  }
  @media (min-width: $tablet_width) {
    &-content {
      .top {
        margin: 0 0 $mpadding * 2 0;
        .text {
          font-size: 42px;
        }
      }
      .sub {
        margin: $mpadding 0 0 0;
        padding: 0 0 0 $mpadding * 1.5;
        .text {
          font-size: 28px;
        }
      }
    }
    &-animation {
      padding: 10% 0 0 5%;
    }
  }
  @media (min-width: $pc_width) {
    &-animation {
      padding: 10% 0 0 10%;
    }
  }
}
</style>
