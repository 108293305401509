<template>
  <div :class="`text ${_class}`">
    <div class="wrap">
      <div class="intxt">
        <div class="txt">{{ text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["_class", "text"],
};
</script>
