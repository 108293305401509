<template>
  <div class="landing">
    <div class="landing-content">
      <LandingColumnMobile v-if="is_mobile" :users="users" :index="0" ref="s0" @afterChange0="afterChange" />
      <LandingColumn v-else :user="usersColumns[0]" :index="0" ref="s0" @afterChange0="afterChange" />
      <LandingColumn :user="usersColumns[1]" :index="1" ref="s1" @afterChange1="afterChange" />
      <LandingColumn :user="usersColumns[2]" :index="2" ref="s2" @afterChange2="afterChange" />
      <LandingColumn :user="usersColumns[3]" :index="3" ref="s3" @afterChange3="textStart" />
      <LandingText ref="text" @ended="textDone" />
    </div>
    <TopCreators :creators="users" />
    <Stories />
    <ExploreContent />
    <Spacer num="1" />
    <LandingFooter />
  </div>
</template>

<script>
let columnsInterval;
import landing from "./landing.js";
import LandingColumn from "./LandingColumn.vue";
import LandingColumnMobile from "./LandingColumnMobile.vue";
import LandingColumnLabel from "./LandingColumnLabel.vue";
import LandingText from "./LandingText.vue";
import TopCreators from "./TopCreators.vue";
import LandingFooter from "./LandingFooter.vue";
import Stories from "../stories/Stories.vue";
import ExploreContent from "./ExploreContent.vue";
export default {
  mixins: [landing],
  components: { LandingColumn, LandingColumnMobile, LandingColumnLabel, LandingText, LandingFooter, TopCreators, Stories, ExploreContent },
  data: function() {
    return {
      usersColumns: [],
      onText: false,
      cycle: 0,
      cycleChange: -1,
      cyclesToText: 2,
      cyclesToChange: 1,
      resizingTimeout: 500,
      timeInterval: 6000,
      columnTimeout: 300,
      columnsNum: 4,
    };
  },
  methods: {
    afterChange: function(index) {
      let next = Number(index) + 1;
      if (this.$refs[`s${next}`]) {
        setTimeout(() => {
          this.$refs[`s${next}`]?.next();
        }, this.columnTimeout);
      }
    },
    slideColumns: function() {
      const timeInterval = this.$screen.width < 768 ? this.timeInterval / 2 : this.timeInterval;
      columnsInterval = setInterval(() => {
        if (this.cycle < this.cyclesToText) {
          if (this.$visible && this.$refs[`s0`]) {
            this.$refs[`s0`]?.next();
          }
        }
      }, timeInterval);
    },
    textStart: function() {
      this.cycle++;
      if (this.cycle >= this.cyclesToText) {
        setTimeout(() => {
          this.$refs[`text`]?.start();
        }, this.columnTimeout);
      }
    },
    classAllColumns: function(_class, _type) {
      if (this.is_mobile) return;
      let n = 0;
      while (n < 4) {
        if (_type === "add") {
          this.$refs[`s${n}`]?.$el.classList.add(_class);
        } else {
          this.$refs[`s${n}`]?.$el.classList.remove(_class);
        }
        n++;
      }
    },
    textDone: function() {
      this.cycle = 0;
      this.cycleChange++;
      if (this.cycleChange >= this.cyclesToChange) {
        this.classAllColumns("changing", "add");
        setTimeout(() => {
          this.changeColumns();
        }, this.columnTimeout);
      }
    },
    changeColumns: function() {
      this.cycleChange = 0;
      this.usersColumns = this.getRandom(this.users, this.columnsNum);
      setTimeout(() => {
        this.classAllColumns("changing", "remove");
      }, this.columnTimeout / 4);
    },
    getRandom: function(arr, n) {
      const result = new Set();
      while (result.size < n) {
        result.add(arr[Math.floor(Math.random() * arr.length)]);
      }
      return Array.from(result);
    },
  },
  computed: {
    is_mobile: function() {
      return this.$screen.width < 768;
    },
    users: function() {
      return this.$locale.landing.creators;
    },
  },
  mounted: function() {
    this.cycle = this.cyclesToText;
    clearInterval(columnsInterval);
    this.textStart();
    this.slideColumns();
  },
  beforeMount: function() {
    this.usersColumns = this.getRandom(this.users, this.columnsNum);
  },
  destroyed: function() {
    clearInterval(columnsInterval);
  },
};
</script>

<style lang="scss">
.landing {
  display: block;
  height: calc(100vh - #{$header-height});
  background-color: #000;
  &-content {
    /* pointer-events: none; */
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    .creator-ban {
      position: relative;
      width: 25%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      &:not(:first-child) {
        border-left: solid 3px;
      }
      &:nth-child(1) {
        width: 100%;
      }
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        display: none;
      }
      &:nth-child(4) {
        display: none;
      }
      .creator-label {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: $mpadding * 2;
        text-align: left;
        text-shadow: 0 0 10.02px #000;
        background: linear-gradient(#00000008 10%, #000000);
        &-name {
          font-size: 200%;
          color: white;
          font-family: $sec_font;
          font-weight: lighter;
          margin: 0;
        }
        &-title {
          color: $athens_white;
        }
      }
      .slick-slider {
        transition: 0.2s;
      }
      &.changing {
        .slick-slider {
          opacity: 0;
        }
      }
    }
  }
  @media (min-width: $tablet_width) {
    .creator-ban {
      &:nth-child(1) {
        width: 50%;
        display: block;
        .creator-label {
          &-name,
          &-title {
            color: #fff;
          }
        }
      }
      &:nth-child(2) {
        width: 50%;
        display: block;
      }
      &:nth-child(3) {
        display: none;
      }
      &:nth-child(4) {
        display: none;
      }
    }
  }
  @media (min-width: $pc_width) {
    .creator-ban {
      &:nth-child(1) {
        width: 33%;
        display: block;
      }
      &:nth-child(2) {
        width: 33%;
        display: block;
      }
      &:nth-child(3) {
        width: 33%;
        display: block;
      }
      &:nth-child(4) {
        display: none;
      }
    }
  }
  @media (min-width: $content_width) {
    .creator-ban {
      &:nth-child(1) {
        width: 25%;
        display: block;
      }
      &:nth-child(2) {
        width: 25%;
        display: block;
      }
      &:nth-child(3) {
        width: 25%;
        display: block;
      }
      &:nth-child(4) {
        width: 25%;
        display: block;
      }
    }
  }
}
</style>
