<template>
  <div class="creator-label" v-if="$locale && creator">
    <h1 class="creator-label-name">{{ creator.name }}</h1>
    <div class="creator-label-title">
      <span class="exclusive" v-if="creator.exclusive">{{ $locale["landing"]["model-exclusive-label"] }}</span>
      <span v-else>{{ $locale["landing"]["model-label"] }}</span>
      <iconic v-if="creator.exclusive" name="mf_check" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["creator"],
};
</script>

<style lang="scss">
.creator-label {
  &-title {
    display: flex;
    align-items: center;
    .iconic {
      margin: 0 0 0 $mpadding/3;
      font-size: 150%;
      color: $primary_color;
      @include Flex(inherit, center, center);
      position: relative;
    }
    .iconic {
      svg {
        position: relative;
        z-index: 1;
      }
      &::before {
        /* content: ""; */
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 50%;
        position: absolute;
        z-index: 0;
      }
    }
  }
}
</style>
