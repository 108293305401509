<template>
  <div class="creator-ban" v-if="users && colUsers">
    <VueSlickCarousel v-bind="settings" ref="carrousel" :key="`carrousel-${index}`">
      <div class="creator-ban-wrap" v-for="(user, uindex) in colUsers" :key="`user-${uindex}`">
        <div class="creator-ban-picture" :style="`--picture:url(${encode_url(getUserPicture(user))});`"></div>
        <LandingColumnLabel :name="user.name" :label="user.label" />
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import LandingColumn from "./LandingColumn.vue";
import LandingColumnLabel from "./LandingColumnLabel.vue";
export default {
  mixins: [LandingColumn],
  components: { LandingColumnLabel },
  props: ["users", "index"],
  data: function() {
    return {
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        initialSlide: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 1000,
      },
    };
  },
  computed: {
    colUsers: function() {
      let users = this.users && [...this.users];

      if (!users) return null;

      users[0] = this.users[2];
      users[2] = this.users[0];
      return users;
    },
  },
  methods: {
    getLandingData: function(user) {
      if (!user.landing) return user.files;

      let files = [];
      let keys = Object.keys(user.landing);
      let ignoreKey = ["top", "stories"];

      for (let i = 0; i < keys.length; i++) {
        if (user.landing[keys[i]].src && !ignoreKey.includes(keys[i])) {
          files.push(user.landing[keys[i]]);
        }
      }

      if (!files.length) {
        files = user.files;
      }

      return files;
    },
    getUserPicture: function(user) {
      let pictures = [...this.getLandingData(user)];
      return pictures[0] && pictures[0].src;
    },
    next: function() {
      this.$refs.carrousel.next();
      setTimeout(() => {
        this.$emit(`afterChange${this.index}`, this.index);
      }, this.settings.speed);
    },
  },
};
</script>

<style lang="scss">
.creator-ban {
  pointer-events: none;
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > div,
  &-wrap,
  &-picture {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &-picture {
    background-image: var(--picture);
    background-size: cover;
    background-position: top center;
    > * {
      pointer-events: none;
    }
  }
}
</style>
