<template>
  <section class="home-section" v-if="$locale && $locale.landing">
    <Header />
    <Landing />
    <router-view />
  </section>
</template>

<script>
import Header from "../../components/home/Header.vue";
import Landing from "../../components/landing/Landing.vue";

export default {
  components: { Header, Landing },
  metaInfo: function() {
    return {
      title: `My Fans 1A`,
    };
  },
};
</script>
